import React, { useEffect } from 'react';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import WizzgridModules from './components/WizzGridModules';
import References from './components/References';
import FeaturesSection from './components/Features';
import AboutUs from './components/AboutUs';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Introduction from './components/Introduction';
import Benefits from './components/Benefits';
import CookieConsent from './components/CookieConsent';
import { logPageView } from './utils/analytics';
import './i18n';
import DataSecuritySection from './components/DataSecuritySection';

function App() {
  useEffect(() => {
    logPageView();
  }, []);

  return (
    <div>
      <Header/>
      <Hero/>
      <Introduction/>
      <FeaturesSection/>
      <Benefits/>
      <WizzgridModules/>
      <References/>
      <AboutUs/>
      <DataSecuritySection/>
      <Contact/>
      <Footer/>
      <CookieConsent/>
    </div>
  );
}

export default App;