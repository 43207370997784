import React from 'react';
import { useTranslation } from 'react-i18next';
import { Swiper as SwiperCore, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import Hexoid, { HexoidProps } from './Hexoid';

const Benefits: React.FC = () => {
  const { t } = useTranslation();

  const hexoidData: HexoidProps[] = [
    { title: t('benefits.customer.title'), text: t('benefits.customer.text'), color: "blue" },
    { title: t('benefits.control.title'), text: t('benefits.control.text'), color: "white" },
    { title: t('benefits.effectiveness.title'), text: t('benefits.effectiveness.text'), color: "gray" },
    { title: t('benefits.future.title'), text: t('benefits.future.text'), color: "green" },
    { title: t('benefits.visibility.title'), text: t('benefits.visibility.text'), color: "blue" }
  ];

  return (
    <div id="benefits" className="container mx-auto px-4">
        <div className="flex flex-col items-center mt-14 mb-8">
            <h1 className="text-5xl font-bold text-primary">{t('benefits.sectionTitle')}</h1>
            <h3 className="text-xl text-primary mt-4">{t('benefits.subtitle')}</h3>
        </div>
      <div className="md:hidden">
        <SwiperCore
          modules={[Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ clickable: true }}
        >
          {hexoidData.map((hexoid) => (
            <SwiperSlide key={hexoid.title}>
              <div className="flex justify-center py-8">
                <Hexoid {...hexoid} />
              </div>
            </SwiperSlide>
          ))}
        </SwiperCore>
      </div>

      <div className="hidden md:block">
        <div className="flex flex-col items-center">
          <div className="flex justify-center -mx-3">
            {hexoidData.slice(0, 3).map((hexoid) => (
              <div key={hexoid.title} className="mx-3">
                <Hexoid {...hexoid} />
              </div>
            ))}
          </div>
          <div className="flex justify-center -mx-3 -mt-14">
            {hexoidData.slice(3).map((hexoid) => (
              <div key={hexoid.title} className="mx-3">
                <Hexoid {...hexoid} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;